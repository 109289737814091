import alexandracolliaux from "@/assets/images/landing/alexandra-colliaux.png";
import yasminaadila from "@/assets/images/landing/yasmina-adila.png";

export interface ReviewData {
  id: string;
  name: string;
  imagePath: string;
  review: string;
  postedAt: string;
}

export const reviewsData: ReviewData[] = [
  {
    id: "yasmina-adila",
    name: "Yasmina Adila",
    imagePath: yasminaadila.src,
    review:
      "Le meilleur coiffeur de Sainte-Geneviève-des-Bois ! Et j'en ai testé ! Accueil agréable et prestations géniales ! Toutes les coiffeuses de ce salon sont très accueillantes, agréables, pro et de bon conseil. Merci à Alexandra et son équipe. Je recommande fortement ce salon.",
    postedAt: "il y a un an",
  },
  {
    id: "alexandra-colliaux",
    name: "Alexandra Colliaux",
    imagePath: alexandracolliaux.src,
    review:
      "Un excellent salon avec une excellente équipe. Cela fait quelques années que je viens ici et tout est toujours parfait ! Aucun regret de venir de Fécamp juste pour me faire coiffer et chouchouter. Merci a toute l'équipe et particulièrement a Jessy.",
    postedAt: "il y a 5 mois",
  },
  {
    id: "yasmina-adila2",
    name: "Yasmouuuna Adila",
    imagePath: yasminaadila.src,
    review:
      "Le meilleur coiffeur de Sainte-Geneviève-des-Bois ! Et j'en ai testé ! Accueil agréable et prestations géniales ! Toutes les coiffeuses de ce salon sont très accueillantes, agréables, pro et de bon conseil. Merci à Alexandra et son équipe. Je recommande fortement ce salon.",
    postedAt: "il y a un an",
  },
  {
    id: "alexandra-colliaux2",
    name: "Alexandrouu Colliaux",
    imagePath: alexandracolliaux.src,
    review:
      "Un excellent salon avec une excellente équipe. Cela fait quelques années que je viens ici et tout est toujours parfait ! Aucun regret de venir de Fécamp juste pour me faire coiffer et chouchouter. Merci a toute l'équipe et particulièrement a Jessy.",
    postedAt: "il y a 5 mois",
  },
  {
    id: "peter-stat",
    name: "Peter Stat",
    imagePath: alexandracolliaux.src,
    review:
      "Le meilleur coiffeur de Sainte-Geneviève-des-Bois ! Et j'en ai testé ! Accueil agréable et prestations géniales ! Toutes les coiffeuses de ce salon sont très accueillantes, agréables, pro et de bon conseil. Merci à Alexandra et son équipe. Je recommande fortement ce salon.",
    postedAt: "il y a 5 mois",
  },
  {
    id: "tom-tom",
    name: "Tom Tom",
    imagePath: alexandracolliaux.src,
    review:
      "Un excellent salon avec une excellente équipe. Cela fait quelques années que je viens ici et tout est toujours parfait ! Aucun regret de venir de Fécamp juste pour me faire coiffer et chouchouter. Merci a toute l'équipe et particulièrement a Jessy.",
    postedAt: "il y a 5 mois",
  },
];
