import { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ReviewData, reviewsData } from "./data";
import { ICStar } from "@/assets/icons/ic-star";
import { ICArrowLeft } from "@/assets/icons/ph-arrow-left";
import { ICArrowRight } from "@/assets/icons/ph-arrow-right";
import Image from "next/image";

export const ReviewsSection = () => {
  const [currentPairIndex, setCurrentPairIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isMobile, setIsMobile] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = useCallback(() => {
    if (isAnimating) return;
    setIsAnimating(true);
    setDirection(1);

    if (isMobile) {
      setCurrentPairIndex((prev) =>
        prev === reviewsData.length - 1 ? 0 : prev + 1,
      );
    } else {
      setCurrentPairIndex((prev) =>
        prev >= reviewsData.length - 2 ? 0 : prev + 2,
      );
    }
  }, [isAnimating, isMobile]);

  const handlePrevious = useCallback(() => {
    if (isAnimating) return;
    setIsAnimating(true);
    setDirection(-1);

    if (isMobile) {
      setCurrentPairIndex((prev) =>
        prev === 0 ? reviewsData.length - 1 : prev - 1,
      );
    } else {
      setCurrentPairIndex((prev) =>
        prev === 0 ? Math.floor((reviewsData.length - 1) / 2) * 2 : prev - 2,
      );
    }
  }, [isAnimating, isMobile]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (!isPaused) {
      timer = setInterval(() => {
        handleNext();
      }, 5000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [currentPairIndex, isPaused, handleNext]);

  const getCurrentReviews = () => {
    if (isMobile) {
      return [reviewsData[currentPairIndex]];
    }
    return [
      reviewsData[currentPairIndex],
      reviewsData[currentPairIndex + 1] || reviewsData[0],
    ];
  };

  const ReviewCard = ({ review }: { review: ReviewData }) => (
    <div
      className="flex-1 bg-primary p-6 text-white"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <div className="mb-4 flex">
        {[...Array(5)].map((_, i) => (
          <ICStar key={i} className="h-5 w-5 text-yellow-400" />
        ))}
      </div>

      <p className="mb-8 text-sm">{review.review}</p>

      <div className="mb-8 h-[1px] w-full bg-white opacity-30"></div>

      <div className="flex items-center gap-3">
        <div className="h-10 w-10 overflow-hidden rounded-full">
          {review.imagePath ? (
            <Image
              src={review.imagePath}
              alt={review.name}
              width={40}
              height={40}
              className="h-full w-full object-cover"
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center text-lg">
              {review.name[0]}
            </div>
          )}
        </div>
        <div>
          <p className="font-medium">{review.name}</p>
          <p className="text-sm text-gray-400">{review.postedAt}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex w-full flex-col gap-8 bg-tertiary p-4 px-4 py-20 ph:px-24 md:flex-row md:px-48 lg:px-72 xl:px-96">
      <div className="flex flex-col justify-between md:w-1/3 lg:gap-4 2xl:gap-6">
        <div className="mb-8 md:mb-0">
          <h2 className="font-cormorant text-3xl text-primary xs:text-4xl ph:text-5xl sm:text-6xl md:mb-4 lg:text-7xl">
            AVIS
          </h2>
          <p className="mb-6 text-lg">
            Voici ce que disent nos clientes de nous !<br />
            Vous aussi, laissez nous votre avis :
          </p>
          <button className="group relative overflow-hidden border border-black px-8 py-2">
            <span className="relative z-10 transition-colors group-hover:text-white">
              Laisser un avis
            </span>
            <div className="absolute bottom-0 left-0 h-full w-0 bg-primary transition-all duration-300 ease-out group-hover:w-full"></div>
          </button>
        </div>

        <div
          className="flex gap-4"
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <button
            onClick={handlePrevious}
            className="rounded-full border border-black p-4 transition-colors hover:bg-primary hover:text-white"
            disabled={isAnimating}
          >
            <ICArrowLeft className="h-6 w-6" />
          </button>
          <button
            onClick={handleNext}
            className="rounded-full border border-black p-4 transition-colors hover:bg-primary hover:text-white"
            disabled={isAnimating}
          >
            <ICArrowRight className="h-6 w-6" />
          </button>
        </div>
      </div>

      <div className="relative md:w-2/3">
        <div className="overflow-hidden">
          <AnimatePresence mode="wait" custom={direction}>
            <motion.div
              key={currentPairIndex}
              custom={direction}
              initial={{
                opacity: 0,
                x: direction * 200,
              }}
              animate={{
                opacity: 1,
                x: 0,
              }}
              exit={{
                opacity: 0,
                x: direction * -200,
              }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 30,
              }}
              onAnimationComplete={() => setIsAnimating(false)}
              className="flex flex-col gap-4 md:flex-row"
            >
              {getCurrentReviews().map((review, index) => (
                <ReviewCard
                  key={`${currentPairIndex}-${index}`}
                  review={review}
                />
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
