import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  server: {},
  client: {
    NEXT_PUBLIC_API_URL: z.string().min(1),
    NEXT_PUBLIC_MAPBOX_TOKEN: z.string().min(1),
  },
  runtimeEnv: {
    NEXT_PUBLIC_API_URL:
      process.env.NEXT_PUBLIC_API_URL || "http://localhost:3000",
    NEXT_PUBLIC_MAPBOX_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_TOKEN || "",
  },
  skipValidation: process.env.NODE_ENV === "development",
});

// export const env = createEnv({
//   server: {
//     // Variables serveur ici si nécessaire
//   },
//   client: {
//     NEXT_PUBLIC_API_URL: z.string().url(),
//   },
//   runtimeEnv: {
//     NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
//   },
//   skipValidation: !!process.env.SKIP_ENV_VALIDATION,
// });
