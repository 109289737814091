import { Popup } from "react-map-gl";
import { Icon } from "@iconify/react";
import type { Location } from "./findUs.types";

interface LocationPopupProps {
  location: Location;
  copiedId: number | null;
  onClose: () => void;
  onCopy: (address: string, id: number) => void;
}

export function LocationPopup({
  location,
  copiedId,
  onClose,
  onCopy,
}: LocationPopupProps) {
  return (
    <Popup
      longitude={location.coordinates[0]}
      latitude={location.coordinates[1]}
      anchor="bottom"
      onClose={onClose}
      closeButton={true}
      closeOnClick={false}
      offset={25}
    >
      <div className="bg-black p-2">
        <h3 className="font-poppins text-[16px] font-semibold text-white">
          {location.name}
        </h3>
        <p className="mt-1 text-sm text-gray-400">{location.address}</p>
        <div className="mt-4 flex gap-2">
          <button
            onClick={() => onCopy(location.address, location.id)}
            className="flex items-center gap-1 rounded-md bg-white px-3 py-1 text-sm text-black transition-colors hover:bg-gray-200"
          >
            {copiedId === location.id ? "Copié !" : "Copier l'adresse"}
            <Icon icon="tabler:copy" className="h-4 w-4" />
          </button>
          <a
            href={location.googleMapsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center whitespace-nowrap rounded-md border-2 border-white bg-black px-3 py-1 text-sm text-white transition-colors hover:bg-gray-800"
          >
            Y aller
          </a>
        </div>
      </div>
    </Popup>
  );
}
