export interface Location {
  id: number;
  name: string;
  address: string;
  coordinates: [number, number];
  googleMapsUrl: string;
}

export const LOCATIONS: Location[] = [
  {
    id: 1,
    name: "Au Grey des couleurs",
    address: "1 Rue Eugène Varlin, 94800 Villejuif",
    coordinates: [2.3631880701334005, 48.794830396400094],
    googleMapsUrl: "https://maps.app.goo.gl/kaKim8vwWFTVaXvX8",
  },
  {
    id: 2,
    name: "Alexandra Grey - Choisy-le-Roi",
    address: "2 Pl. de l'Église, 94600 Choisy-le-Roi",
    coordinates: [2.4070129836274776, 48.76631457757672],
    googleMapsUrl: "https://maps.app.goo.gl/teL2fWSnmJDsNoir9",
  },
  {
    id: 3,
    name: "Alexandra Grey - Villejuif",
    address: "61 Rue Jean Jaurès, 94800 Villejuif",
    coordinates: [2.3667598143077564, 48.793904892424955],
    googleMapsUrl: "https://maps.app.goo.gl/9mSHdATVAWaGezhYA",
  },
  {
    id: 4,
    name: "Alexandra Grey - Ville d'Avray",
    address: "28 Rue de Saint-Cloud, 92410 Ville-d'Avray",
    coordinates: [2.190979341300038, 48.82852616031252],
    googleMapsUrl: "https://maps.app.goo.gl/bsygmY1hJNLwFcRh8",
  },
];
