"use client";

import Image from "next/image";
import coiffuremariage from "@/assets/images/landing/coiffure-mariage.png";
import coiffuresoiree from "@/assets/images/landing/coiffure-soiree.png";
import soinvisage from "@/assets/images/landing/soin-visage.png";
import Link from "next/link";

export const PrestationsSection = () => {
  return (
    <section
      className="max-w-8xl w-full px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96"
      id="prestations"
    >
      <h2 className="mb-12 font-cormorant text-3xl text-primary xs:text-4xl ph:text-5xl sm:text-6xl lg:mb-16 lg:text-7xl">
        PRESTATIONS
      </h2>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {/* Left column */}
        <div className="flex flex-col gap-4">
          <div className="group relative h-[400px] md:h-[300px]">
            <Link href="/prestations/coiffure-soiree">
              <Image
                src={coiffuresoiree}
                alt="Coiffure de soirée"
                fill
                className="object-cover transition-all duration-300 group-hover:grayscale"
              />
              <div className="absolute inset-0 flex items-end bg-black/20">
                <h3 className="p-6 text-xl text-white md:text-2xl">
                  COIFFURE DE SOIRÉE
                </h3>
              </div>
            </Link>
          </div>

          <div className="group relative h-[400px] md:h-[300px]">
            <Link href="/prestations/soins-visage">
              <Image
                src={soinvisage}
                alt="Soins du visage"
                fill
                className="object-cover transition-all duration-300 group-hover:grayscale"
              />
              <div className="absolute inset-0 flex items-end bg-black/20">
                <h3 className="p-6 text-xl text-white md:text-2xl">
                  SOINS DU VISAGE
                </h3>
              </div>
            </Link>
          </div>
        </div>

        {/* Right column */}
        <div className="group relative h-[400px] md:h-[616px]">
          <Link href="/prestations/coiffure-mariage">
            <Image
              src={coiffuremariage}
              alt="Coiffure de mariage"
              fill
              className="object-cover transition-all duration-300 group-hover:grayscale"
            />
            <div className="absolute inset-0 flex items-end bg-black/20">
              <h3 className="p-6 text-xl text-white md:text-2xl">
                COIFFURE DE MARIAGE
              </h3>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};
