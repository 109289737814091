"use client";

import hero from "@/assets/images/landing/hero.png";
import Image from "next/image";
import scrollDown from "@/assets/images/icons/Down.png";

export const HeroSection = () => {
  const scrollToPrestations = () => {
    const prestationsSection = document.getElementById("prestations");
    prestationsSection?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section
      id="hero-section"
      className="flex min-h-[100dvh] w-full flex-col items-center justify-center gap-32 overflow-x-hidden bg-cover bg-center bg-no-repeat px-4 ph:px-24 md:px-48 lg:px-72 xl:px-96"
      style={{
        backgroundImage: `linear-gradient(rgba(25, 25, 25, 0.75), rgba(25, 25, 25, 0.75)), url(${hero.src})`,
      }}
    >
      <div className="flex flex-col items-center justify-center text-center">
        <span className="text-md mb-2 uppercase tracking-wider text-white ph:mb-4 ph:text-lg">
          4 salons · 1 vision
        </span>

        <h1 className="mb-[-1rem] font-cormorant text-4xl uppercase leading-[0.9] tracking-wider text-white xs:text-5xl ph:mb-[-1.5rem] ph:text-6xl sm:text-7xl md:mb-[-2rem] md:text-8xl lg:text-9xl">
          Coiffure <br /> & Visagiste
        </h1>
        <span className="mb-8 font-pencerio text-3xl text-gold sm:text-4xl md:text-5xl lg:text-6xl">
          Alexandra Grey
        </span>

        <p className="text-md max-w-xs text-white ph:text-lg">
          Le concept Alexandra Grey est né de notre vision du métier basée sur
          nos valeurs humaines.
        </p>
      </div>
      <div
        onClick={scrollToPrestations}
        className="flex flex-col items-center justify-center text-white"
      >
        <div className="scroll-down-wrapper">
          <Image
            src={scrollDown}
            alt="scroll down"
            className="relative z-10 cursor-pointer"
            width={48}
            height={48}
          />
        </div>
      </div>
    </section>
  );
};
