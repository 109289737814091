"use client";

import { useState, useMemo, useRef } from "react";
import Map, { Marker } from "react-map-gl";
import type { MapRef } from "react-map-gl";
import { Icon } from "@iconify/react";
import { env } from "@/utils/env";
import { LOCATIONS, type Location } from "./findUs.types";
import { LocationPopup } from "./LocationPopup";
import "mapbox-gl/dist/mapbox-gl.css";

export function FindUsSection() {
  const mapRef = useRef<MapRef>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );
  const [copiedId, setCopiedId] = useState<number | null>(null);

  const mapConfig = useMemo(
    () => ({
      longitude: 2.363,
      latitude: 48.767,
      zoom: 10,
    }),
    [],
  );

  const handleCopyAddress = async (address: string, locationId: number) => {
    try {
      await navigator.clipboard.writeText(address);
      setCopiedId(locationId);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error("Failed to copy address:", err);
    }
  };

  const handleZoomIn = () => {
    if (mapRef.current) {
      mapRef.current.zoomIn();
    }
  };

  const handleZoomOut = () => {
    if (mapRef.current) {
      mapRef.current.zoomOut();
    }
  };

  return (
    <section className="w-full px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96">
      <div className="max-w-8xl mx-auto">
        <h2 className="mb-12 text-left font-cormorant text-3xl text-primary xs:text-4xl ph:text-5xl sm:text-6xl lg:mb-16 lg:text-7xl">
          Où nous trouver
        </h2>

        <div className="h-[600px] w-full overflow-hidden rounded-lg">
          <Map
            ref={mapRef}
            mapboxAccessToken={env.NEXT_PUBLIC_MAPBOX_TOKEN}
            initialViewState={mapConfig}
            style={{ width: "100%", height: "100%" }}
            mapStyle="mapbox://styles/mapbox/streets-v12"
            attributionControl={false}
            reuseMaps
            maxZoom={16}
            minZoom={8}
            scrollZoom={{
              speed: 0.5,
              smooth: true,
            }}
            trackResize={false}
            renderWorldCopies={false}
            cooperativeGestures={true}
            refreshExpiredTiles={false}
          >
            <div className="absolute right-2 top-2 z-10 flex flex-col gap-1">
              <button
                onClick={handleZoomIn}
                className="flex h-8 w-8 items-center justify-center rounded-md bg-white shadow-md transition-colors hover:bg-gray-50"
                aria-label="Zoom in"
                type="button"
              >
                <Icon icon="mdi:plus" className="h-5 w-5 text-gray-700" />
              </button>
              <button
                onClick={handleZoomOut}
                className="flex h-8 w-8 items-center justify-center rounded-md bg-white shadow-md transition-colors hover:bg-gray-50"
                aria-label="Zoom out"
                type="button"
              >
                <Icon icon="mdi:minus" className="h-5 w-5 text-gray-700" />
              </button>
            </div>

            {LOCATIONS.map((location) => (
              <Marker
                key={location.id}
                longitude={location.coordinates[0]}
                latitude={location.coordinates[1]}
                anchor="bottom"
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  setSelectedLocation(location);
                }}
              >
                <Icon
                  icon="ph:map-pin-simple"
                  className="h-10 w-10 cursor-pointer text-primary transition-colors hover:text-primary/80"
                  aria-label={location.name}
                />
              </Marker>
            ))}

            {selectedLocation && (
              <LocationPopup
                location={selectedLocation}
                copiedId={copiedId}
                onClose={() => setSelectedLocation(null)}
                onCopy={handleCopyAddress}
              />
            )}
          </Map>
        </div>
      </div>
    </section>
  );
}
