import { StaticImageData } from "next/image";
import casadelgrey1 from "@/assets/images/collections/casa-del-grey/casa-del-grey1.png";
import casadelgrey2 from "@/assets/images/collections/casa-del-grey/casa-del-grey2.png";
import casadelgrey3 from "@/assets/images/collections/casa-del-grey/casa-del-grey3.png";
import casadelgrey4 from "@/assets/images/collections/casa-del-grey/casa-del-grey4.png";
import casadelgrey5 from "@/assets/images/collections/casa-del-grey/casa-del-grey5.png";
import casadelgrey6 from "@/assets/images/collections/casa-del-grey/casa-del-grey6.png";
import casadelgrey7 from "@/assets/images/collections/casa-del-grey/casa-del-grey7.png";
import casadelgrey8 from "@/assets/images/collections/casa-del-grey/casa-del-grey8.png";

import collectionsBanner from "@/assets/images/banners/collections-banner.png";

import splash from "@/assets/images/collections/splash.png";
import browngrey from "@/assets/images/collections/browngrey.png";
import darkgrey from "@/assets/images/collections/darkgrey.png";

interface CollectionData {
  id: string;
  title: string;
  description: string;
  images: StaticImageData[];
  date: string;
  bannerImage: StaticImageData;
}

export const Collections: CollectionData[] = [
  {
    id: "casa-del-grey",
    title: "Casa del Grey",
    description: `
    <div class="flex flex-col gap-2">
      <p>Dans cette collection audacieuse, nous explorons le mariage saisissant du rouge éclatant et du vert profond. Chaque pièce incarne l'essence du luxe moderne, avec des silhouettes architecturales qui défient les conventions traditionnelles.</p>      
      <p>Les costumes et robes de cette collection redéfinissent l'élégance contemporaine, en jouant avec les volumes et les coupes précises. L'utilisation du monochrome crée un impact visuel puissant qui capture l'essence de la mode avant-gardiste.</p>
      <p>Cette collection représente un nouveau chapitre dans notre histoire créative, où la sophistication rencontre l'audace, créant une symphonie visuelle qui célèbre l'individualité et la confiance.</p>
    </div>
    `,
    images: [
      casadelgrey6,
      casadelgrey1,
      casadelgrey2,
      casadelgrey3,
      casadelgrey4,
      casadelgrey5,
      casadelgrey7,
      casadelgrey8,
    ],
    date: "02/24",
    bannerImage: collectionsBanner,
  },
  {
    id: "collection-automne-2023",
    title: "Collection de mariage",
    description: `
    <div class="flex flex-col">
      <p>Notre collection automne 2023 célèbre l'amour dans toute sa splendeur automnale. Les tissus luxueux et les coupes raffinées s'harmonisent avec les couleurs riches de la saison, créant une collection qui respire l'élégance intemporelle.</p>
      <p>Chaque pièce est conçue pour capturer la magie des célébrations automnales, avec des détails minutieux qui reflètent la beauté naturelle de la saison. Les silhouettes fluides et les textures somptueuses créent une atmosphère de romance sophistiquée.</p>
      <p>Cette collection incarne notre vision d'un mariage moderne, où tradition et innovation se rencontrent pour créer des moments inoubliables.</p>
    </div>
    `,
    images: [splash],
    date: "09/23",
    bannerImage: collectionsBanner,
  },
  {
    id: "collection-ete-2023",
    title: "Collection de mariage",
    description: `
    <div class="flex flex-col">
      <p>Notre collection automne 2023 célèbre l'amour dans toute sa splendeur automnale. Les tissus luxueux et les coupes raffinées s'harmonisent avec les couleurs riches de la saison, créant une collection qui respire l'élégance intemporelle.</p>
      <p>Chaque pièce est conçue pour capturer la magie des célébrations automnales, avec des détails minutieux qui reflètent la beauté naturelle de la saison. Les silhouettes fluides et les textures somptueuses créent une atmosphère de romance sophistiquée.</p>
      <p>Cette collection incarne notre vision d'un mariage moderne, où tradition et innovation se rencontrent pour créer des moments inoubliables.</p>
    </div>
    `,
    images: [browngrey],
    date: "06/23",
    bannerImage: collectionsBanner,
  },
  {
    id: "collection-automne-2022",
    title: "Collection de mariage",
    description: `
    <div class="flex flex-col">
      <p>Notre collection automne 2023 célèbre l'amour dans toute sa splendeur automnale. Les tissus luxueux et les coupes raffinées s'harmonisent avec les couleurs riches de la saison, créant une collection qui respire l'élégance intemporelle.</p>
      <p>Chaque pièce est conçue pour capturer la magie des célébrations automnales, avec des détails minutieux qui reflètent la beauté naturelle de la saison. Les silhouettes fluides et les textures somptueuses créent une atmosphère de romance sophistiquée.</p>
      <p>Cette collection incarne notre vision d'un mariage moderne, où tradition et innovation se rencontrent pour créer des moments inoubliables.</p>
    </div>
    `,
    images: [darkgrey],
    date: "09/22",
    bannerImage: collectionsBanner,
  },
];
