import Link from "next/link";

export const CTA = () => {
  return (
    <div className="flex h-[10dvh] w-full items-center justify-center bg-[#191919]">
      <div className="">
        <Link
          href="/reservation"
          className="group relative inline-block pb-[2px] text-white"
        >
          <span>Prendre rendez-vous</span>
          <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
            <div className="bg-gold h-full w-0 transition-all duration-300 ease-out group-hover:w-full"></div>
          </div>
        </Link>
      </div>
    </div>
  );
};
